/**
 * Created by Tom on 28.02.2017.
 */
import xhr from 'xhr'
import Swiper from 'swiper'
import selectAll from './select-all'
import initModal from './modal'
import formSubmit from './form-submit'

function getDetail (e) {
  e.preventDefault()
  let data = {
    'mode': 'getMobileDetail',
    'adsID': this.getAttribute('data-id')
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      console.log(err)
    } else {
      let content = document.getElementById('detail-content')
      let search = document.getElementById('moblie-content')
      body = JSON.parse(body)
      if (content) {
        content.innerHTML = body.detail
        content.classList.remove('hidden')
        search.classList.add('hidden')
        document.getElementById('car-id').value = body.car
        let backListe = selectAll('.back-to-liste')
        backListe.map(form => form.addEventListener('click', swtichtoListe))
        new Swiper('#content-slider', { // eslint-disable-line
          slidesPerView: 1,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          spaceBetween: 0,
          speed: 2000,
          autoplay: 6000
        })
        initModal()
        formSubmit()
      }
    }
  })
  return false
}

function getSearch () {
  let data = {
    'mode': 'getMobileSearch',
    'marke': document.getElementById('search-marke').value,
    'model': document.getElementById('search-model').value,
    'color': document.getElementById('search-color').value,
    'power': document.getElementById('search-power').value,
    'fuel': document.getElementById('search-fuel').value,
    'registration': document.getElementById('search-registration').value,
    'category': document.getElementById('search-category').value,
    'pricemax': document.getElementById('search-pricemax').value
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      console.log(err)
    } else {
      let search = document.getElementById('moblie-content')
      body = JSON.parse(body)
      if (search) {
        search.innerHTML = body.html
        mobile()
      }
    }
  })
}
function getStartSearch () {
  let data = {
    'mode': 'getMobileStartSearch',
    'marke': document.getElementById('search-marke').value,
    'model': document.getElementById('search-model').value,
    'fuel': document.getElementById('search-fuel').value,
    'pricemax': document.getElementById('search-pricemax').value
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      console.log(err)
    } else {
      let startBox = document.getElementById('start-search-box')
      body = JSON.parse(body)
      if (startBox) {
        startBox.innerHTML = body.html
        mobile()
      }
    }
  })
}
function swtichtoListe () {
  let content = document.getElementById('detail-content')
  let search = document.getElementById('moblie-content')
  search.classList.remove('hidden')
  content.classList.add('hidden')
}
export default function mobile () {
  let details = selectAll('.mobile-detail')
  let searchChange = selectAll('.mobile-search-change')
  let searchStart = selectAll('.mobile-search-start')
  let searchBlur = selectAll('.mobile-search-blur')
  let backListe = selectAll('.back-to-liste')
  details.map(form => form.addEventListener('click', getDetail))
  searchChange.map(form => form.addEventListener('change', getSearch))
  searchStart.map(form => form.addEventListener('change', getStartSearch))
  searchBlur.map(form => form.addEventListener('blur', getSearch))
  backListe.map(form => form.addEventListener('click', swtichtoListe))
}
