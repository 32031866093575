import 'core-js/fn/object/assign'
import xhr from 'xhr'
import domready from 'domready'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import Swiper from 'swiper'
import baguetteBox from 'baguettebox.js'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import mobile from './modules/mobile'
import initModal from './modules/modal'
import neuwagen from './modules/neuwagen'
import Datepicker from 'Datepicker.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  // eslint-disable-next-line
  new Datepicker('.datepicker');
  // eslint-disable-next-line
  new Swiper('#slider-container', {
    slidesPerView: 1,
    paginationClickable: true,
    spaceBetween: 0,
    speed: 1000,
    autoplay: 6000
  })
  formSubmit()
  initMaps()
  initModal()
  mobile()
  neuwagen()
  initToggleSubMenu()
  baguetteBox.run('.lightbox')

  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  if (document.getElementById('standID')) {
    document.getElementById('standID').addEventListener('change', function () {
      let data = {
        'mode': 'loadKontaktData',
        'standID': this.value
      }
      xhr({
        body: JSON.stringify(data),
        uri: 'ajax.php',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }, function (err, resp, body) {
        if (err) {
          console.log(err)
        } else {
          let startbox = document.getElementById('startbox-kontakt')
          let sidebar = document.getElementById('sidebar-kontakt')
          let header = document.getElementById('header-kontakt')
          body = JSON.parse(body)
          if (startbox) {
            startbox.innerHTML = body.startbox
          }
          if (sidebar) {
            sidebar.innerHTML = body.sidebar
          }
          if (header) {
            header.innerHTML = body.header
          }
        }
      })
    })
  }
})
