import selectAll from './select-all'
function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.remove('hidden')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[ i ].addEventListener('click', function () {
        elem.classList.add('hidden')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) { window.location.reload() }
      }, false)
    }
  }
}
export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        let element = document.getElementById(this.getAttribute('data-target'))
        modal(element)
        return false
      })
    }
  }
}

