/**
 * Created by Tom on 10.11.2016.
 */
/* global google */
import selectAll from './select-all'

function mapInitialize (elem) {
  if (elem.classList.contains('maps-mutiple-marker')) {
    let map
    let zoom = 9
    let data = elem.getAttribute('data-json')
    data = JSON.parse(data)
    let myOptions = {
      zoom: zoom,
      center: new google.maps.LatLng(51.294959, 12.937130),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: false,
      navigationControl: true,
      navigationControlOptions: { style: google.maps.NavigationControlStyle.SMALL },
      mapTypeControl: false,
      scaleControl: true,
      scrollwheel: false
    }
    map = new google.maps.Map(elem, myOptions)
    var bounds = new google.maps.LatLngBounds()
    let i = 0
    for (let key in data) {
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(data[ key ][ 'lat' ], data[ key ][ 'lon' ]),
        map: map
      })
      bounds.extend(marker.position)
      var infowindowe = new google.maps.InfoWindow({
        content: data[ key ][ 'name' ]
      })
      infowindowe.open(map, marker)

      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        infowindowe.open(map, marker)
      })(marker, i))
      i++
    }
    map.fitBounds(bounds)
    var listener = google.maps.event.addListener(map, 'idle', function () {
      map.setZoom(zoom)
      google.maps.event.removeListener(listener)
    })
  } else {
    var lat = elem.getAttribute('data-lat')
    var lon = elem.getAttribute('data-lon')
    var contentString = elem.getAttribute('data-content')
    var geocoder = new google.maps.Geocoder()
    var directionsService = new google.maps.DirectionsService()
    var directionsDisplay = new google.maps.DirectionsRenderer()
    var zoom = 16
    var map
    var myOptions = {
      zoom: zoom,
      center: new google.maps.LatLng(lat, lon),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: false,
      navigationControl: true,
      navigationControlOptions: { style: google.maps.NavigationControlStyle.SMALL },
      mapTypeControl: false,
      scaleControl: true,
      scrollwheel: false
    }

    map = new google.maps.Map(elem, myOptions)
    directionsDisplay.setMap(map)
    directionsDisplay.setOptions({ suppressMarkers: true })
    var markerGeo = new google.maps.LatLng(lat, lon)
    var marker = new google.maps.Marker({
      position: markerGeo,
      map: map
    })
    var infowindow = new google.maps.InfoWindow({
      content: contentString
    })
    infowindow.open(map, marker)

    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(map, marker)
    })
  }

  let getRoute = document.getElementById('getRoute')
  if (getRoute) {
    document.getElementById('getRoute').addEventListener('click', function (e) {
      e.preventDefault()
      let address = document.getElementById('adress').value
      if (address) {
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            map.setCenter(results[ 0 ].geometry.location)
            // Marker Kunde
            let kunde = new google.maps.Marker({
              position: results[ 0 ].geometry.location,
              map: map
            })
            // Bubble Kunde
            var infowindow = new google.maps.InfoWindow({
              content: 'Ihre Adresse:<br>' + address
            })
            google.maps.event.addListener(kunde, 'click', function () {
              infowindow.open(map, kunde)
            })
            // Route berechnen
            var route = {
              origin: kunde.getPosition(),
              destination: marker.getPosition(),
              travelMode: google.maps.DirectionsTravelMode.DRIVING
            }

            directionsService.route(route, function (response, status) {
              console.log(route, response)
              if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response)
              }
            })
          }
        })
      }
      return false
    })
  }
}

export default function initMaps () {
  let maps = selectAll('div.maps-container')
  if (maps !== null) {
    for (var i = maps.length - 1; i >= 0; i--) {
      google.maps.event.addDomListener(window, 'load', mapInitialize(maps[ i ]))
    }
  }
  let standort = document.getElementById('adressStandort')
  if (standort !== null) {
    standort.addEventListener('change', function (e) {
      let coords = this.value
      let mapp = document.getElementById('kontakt-map')
      coords = coords.split(',')
      mapp.setAttribute('data-lon', coords[ 0 ])
      mapp.setAttribute('data-lat', coords[ 1 ])
      mapInitialize(mapp)
    })
  }
}
