/**
 * Created by Tom on 28.02.2017.
 */
import xhr from 'xhr'
import selectAll from './select-all'

function getNeuwagenSearch () {
  let data = {
    'mode': 'getFahrzeuge',
    'neuFree': document.getElementById('search-free').value,
    'neuMarke': document.getElementById('search-marke').value,
    'neuModel': document.getElementById('search-model').value,
    'neuFahrzeug': document.getElementById('search-fahrzeug').value,
    'neuColor': document.getElementById('search-color').value,
    'neuPower': document.getElementById('search-power').value,
    'neuFuel': document.getElementById('search-fuel').value,
    'neuRegistration': document.getElementById('search-registration').value,
    'neuCategory': document.getElementById('search-category').value,
    'neuKlima': document.getElementById('search-klima').checked,
    'neuAutomatik': document.getElementById('search-automatik').checked,
    'neuAllrad': document.getElementById('search-allrad').checked,
    'neuXenon': document.getElementById('search-xenon').checked,
    'neuLederbezug': document.getElementById('search-lederbezug').checked,
    'neuNavi': document.getElementById('search-navi').checked,
    'neuMetallic': document.getElementById('search-metallic').checked
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      console.log(err)
    } else {
      let neuwagen = document.getElementById('neuwagen-content')
      let model = document.getElementById('search-model')
      let fahrzeuge = document.getElementById('search-fahrzeug')
      body = JSON.parse(body)
      if (neuwagen) {
        neuwagen.innerHTML = body.html
        model.innerHTML = body.modelle
        fahrzeuge.innerHTML = body.fahrzeug
      }
    }
  })
}

export default function neuwagen () {
  let searchChange = selectAll('.neuwagen-search-change')
  let searchBlur = selectAll('.neuwagen-search-blur')
  let searchClick = selectAll('.neuwagen-search-click')
  searchChange.map(form => form.addEventListener('change', getNeuwagenSearch))
  searchBlur.map(form => form.addEventListener('blur', getNeuwagenSearch))
  searchBlur.map(form => form.addEventListener('keyup', getNeuwagenSearch))
  searchClick.map(form => form.addEventListener('click', getNeuwagenSearch))
}
